import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import { Card } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CustomButton from "../buttons/Button";
import { IconButton, Tooltip } from "@mui/material";
import { LuCopy } from "react-icons/lu";
import AmountModal from "./AmountModal";
import { switchCurrency } from "../../store/actions/dashboard/switchCurrency";
import { BiChevronLeft } from "react-icons/bi";
import Loader2 from "../sharedLayouts/Loader2";
import mixpanel from "mixpanel-browser";

const CopyToClipboardIcon = ({ textToCopy, disabled }) => {
  const [isCopied, setIsCopied] = useState(false);
  const timeOutRef = useRef();
  useEffect(() => {
    return () => clearTimeout(timeOutRef.current);
  }, []);

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      mixpanel.track("Copied-Payment-Info", {});
      setOpen(true);
      setIsCopied("Copied");
    } catch (error) {
      // console.error("Failed to copy text to clipboard:", error);
    }

    timeOutRef.current = setTimeout(() => {
      setIsCopied(null);
      setOpen(false);
    }, 3000);
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Tooltip
        open={isCopied || open}
        onClose={handleClose}
        onOpen={handleOpen}
        title={isCopied ? "Copied!" : "Copy account info"}
        arrow
      >
        <IconButton
          onClick={handleCopyToClipboard}
          color="primary"
          sx={{
            fontSize: 8,
            color: disabled ? "#555" : "black",
          }}
        >
          <LuCopy size={28} color="#141B34" />
        </IconButton>
      </Tooltip>

      {/* {isCopied && (
        <Typography variant="body2" color="success">
          Copied to clipboard!
        </Typography>
      )} */}
    </>
  );
};

const PaymentModal = ({
  open,
  onClose,
  isUSDModal,
  genericAccountInfo,
  noSubtitle,
  handleGoBack,
  accountInfo,
  loading,
}) => {
  // const _wallet = useSelector(
  //   (state) => state?.dashboard?.halvestBalance?.data
  // );
  const user = useSelector((state) => state.onboarding?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // let wallet = accountInfo || _wallet;
  let wallet = accountInfo;
  const [paymentMethod, setPaymentMethod] = useState("Card");

  const [AmountIsOpen, setAmountIsOpen] = useState(false);

  const accountInfoText = `
    Account Name: ${wallet?.accountName}
    Account Number: ${wallet?.accountNumber}
    Bank Name: ${wallet?.bankName}
  `;

  useEffect(() => {
    if (user && open) {
      mixpanel.track("Viewed-Payment-Method", {});
    }
  }, [user, open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          width: 750,
          maxHeight: "90%",
          overflowX: "auto",
          maxWidth: "90%",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          p: 3,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // margin: "10px",
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>
        <div>
          <div
            onClick={handleGoBack ? handleGoBack : null}
            className={`mb-4 flex items-center gap-1 ${
              handleGoBack ? "cursor-pointer" : ""
            }`}
          >
            {handleGoBack ? <BiChevronLeft size={35} color="#101828" /> : <></>}
            <h4
              style={{
                fontWeight: "600",
                // fontFamily: "TTI",
                fontFamily: "Inter",
                fontSize: "20px",
                color: "#101828",
              }}
            >
              Payment Method
            </h4>
          </div>
          <hr className="mb-5" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginBottom: "15px",
            }}
          >
            {genericAccountInfo?.note ? (
              <></>
            ) : (
              <Card
                onClick={() => setPaymentMethod("Bank")}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",

                  width: "100px",
                  height: "75px",

                  borderColor: paymentMethod === "Bank" ? "#159AA8" : "inherit",
                  cursor: "pointer",
                  boxShadow: "none",
                  border: "1px solid #EAECF0",
                  borderRadius: "8px",
                }}
              >
                <AccountBalanceIcon
                  sx={{
                    color: paymentMethod === "Bank" ? "#159AA8" : "inherit",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: paymentMethod === "Bank" ? "#159AA8" : "inherit",
                  }}
                >
                  Bank
                </Typography>
              </Card>
            )}
          </div>

          <div>
            {noSubtitle ? (
              <></>
            ) : isUSDModal ? (
              genericAccountInfo?.details ? (
                <>
                  <Typography
                    variant="body1"
                    fontSize={14}
                    width={"100%"}
                    height={genericAccountInfo?.details ? null : 100}
                    style={{
                      fontWeight: "500",
                      // fontFamily: "TTI",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      color: "#475467",
                    }}
                  >
                    Make payment using the banking details below and then send
                    evidence of payment to{" "}
                    <a
                      className="cursor-pointer text-primary-color-1"
                      target="_blank"
                      rel="noreferrer"
                      href="mailto:support@halvestco.com"
                    >
                      support@halvestco.com
                    </a>{" "}
                    and copy{" "}
                    <a
                      className="cursor-pointer text-primary-color-1"
                      target="_blank"
                      rel="noreferrer"
                      href="mailto:invest@halvestco.com"
                    >
                      invest@halvestco.com
                    </a>{" "}
                    {/* .The Investment will be uploaded on your dashboard as soon as
              our investment partner acknowledges your proof of payment. */}
                  </Typography>
                  {genericAccountInfo?.details ? (
                    <Typography
                      variant="body1"
                      fontSize={14}
                      width={"98%"}
                      height={100}
                      style={{
                        fontWeight: "500",
                        // fontFamily: "TTI",
                        fontFamily: "Inter",
                        fontSize: "13px",
                        color: "#159AA8",
                        marginTop: "4px",
                      }}
                    >
                      Your wallet will be funded as soon as your payment has
                      been confirmed. This can take a few days depending on
                      where you’re transferring from.
                    </Typography>
                  ) : (
                    <></>
                  )}
                </>
              ) : genericAccountInfo?.subType === "reach-out" ? (
                <>
                  <Typography
                    variant="body1"
                    fontSize={14}
                    width={"100%"}
                    // height={genericAccountInfo?.details ? null : 100}
                    style={{
                      fontWeight: "500",
                      // fontFamily: "TTI",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      color: "#475467",
                    }}
                  >
                    To get account details please send an email to{" "}
                    <a
                      className="cursor-pointer text-primary-color-1"
                      target="_blank"
                      rel="noreferrer"
                      href="mailto:support@halvestco.com"
                    >
                      support@halvestco.com
                    </a>{" "}
                    or{" "}
                    <a
                      className="cursor-pointer text-primary-color-1"
                      target="_blank"
                      rel="noreferrer"
                      href="mailto:rsanusi@halvestco.com"
                    >
                      rsanusi@halvestco.com
                    </a>
                    .{" "}
                    {/* .The Investment will be uploaded on your dashboard as soon as
              our investment partner acknowledges your proof of payment. */}
                  </Typography>
                </>
              ) : (
                <></>
              )
            ) : wallet?.accountNumber ? (
              <>
                <Typography
                  variant="body1"
                  fontSize={14}
                  width={"100%"}
                  // height={100}
                  style={{
                    fontWeight: "500",
                    // fontFamily: "TTI",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    color: "#475467",
                  }}
                >
                  Send money to the bank details below to fund your Halvest
                  account.
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={14}
                  width={"98%"}
                  height={100}
                  style={{
                    fontWeight: "500",
                    // fontFamily: "TTI",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    color: "#159AA8",
                  }}
                >
                  {isUSDModal
                    ? ""
                    : "A service fee of 0.5%, capped at a maximum of ₦100 for every wallet deposit, is charged by our banking partner"}
                </Typography>
              </>
            ) : (
              <></>
            )}
            <div className="flex items-center">
              {genericAccountInfo ? (
                <div>
                  {genericAccountInfo?.details ? (
                    Object.entries(genericAccountInfo?.details).map(
                      (item, index) => {
                        return (
                          <Typography
                            variant="body1"
                            style={{
                              fontWeight: "500",
                              // fontFamily: "TTI",
                              fontFamily: "Inter",
                              fontSize: "14px",
                              color: "#475467",
                              marginBottom: "8px",
                              display: "flex",
                              gap: "8px",
                              flexWrap: "wrap",
                            }}
                          >
                            {item[0]}

                            <span
                              style={{
                                fontWeight: "700",
                                // fontFamily: "TTI",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                color: "#101828",
                              }}
                            >
                              {" "}
                              {item[1]}
                            </span>
                          </Typography>
                        );
                      }
                    )
                  ) : (
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: "500",
                        // fontFamily: "TTI",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        color: "#475467",
                        marginBottom: "8px",
                      }}
                    >
                      {genericAccountInfo.note}
                    </Typography>
                  )}
                </div>
              ) : wallet?.accountNumber ? (
                <div className="ml-2 mb-4 relative">
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: "500",
                      // fontFamily: "TTI",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      color: "#475467",
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    Account Name
                    <span
                      style={{
                        fontWeight: "700",
                        // fontFamily: "TTI",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        color: "#101828",
                      }}
                    >
                      {" "}
                      {wallet?.accountName}
                    </span>
                  </Typography>
                  <Typography
                    variant="body1"
                    marginTop={1}
                    style={{
                      fontWeight: "500",
                      // fontFamily: "TTI",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      color: "#475467",
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    Account Number
                    <span
                      style={{
                        fontWeight: "700",
                        // fontFamily: "TTI",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        color: "#101828",
                      }}
                    >
                      {" "}
                      {wallet?.accountNumber}
                    </span>
                  </Typography>
                  <Typography
                    variant="body1"
                    marginTop={1}
                    style={{
                      fontWeight: "500",
                      // fontFamily: "TTI",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      color: "#475467",
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    Bank Name
                    <span
                      style={{
                        fontWeight: "700",
                        // fontFamily: "TTI",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        color: "#101828",
                      }}
                    >
                      {wallet?.bankName}
                    </span>
                  </Typography>
                </div>
              ) : loading ? (
                <div className="w-full justify-center items-center">
                  <Loader2 />
                </div>
              ) : (
                <div className="ml-2 mb-4 relative">
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: "500",
                      // fontFamily: "TTI",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      color: "#475467",
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "500",
                        // fontFamily: "TTI",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        color: "#101828",
                      }}
                    >
                      {" "}
                      Sorry! You do not have a wallet yet. Contact{" "}
                      <a
                        className="cursor-pointer text-primary-color-1"
                        target="_blank"
                        rel="noreferrer"
                        href="mailto:support@halvestco.com"
                      >
                        support
                      </a>{" "}
                      for assistance.
                    </span>
                  </Typography>
                </div>
              )}
              <div className="mr-4">
                {(wallet?.accountNumber || genericAccountInfo?.details) &&
                !genericAccountInfo?.note &&
                genericAccountInfo?.subType !== "reach-out" ? (
                  <CopyToClipboardIcon
                    disabled={
                      !wallet?.accountNumber || !genericAccountInfo?.details
                    }
                    textToCopy={
                      genericAccountInfo?.details
                        ? Object.entries(genericAccountInfo?.details).reduce(
                            (acc, curr) => {
                              const joinedCurr = curr.join(": ");
                              return `${acc} \n${joinedCurr}`;
                            }
                          )
                        : accountInfoText
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <hr className="m-4" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CustomButton
              variant="outlined"
              customColor="#fff"
              children="Cancel"
              width="130px"
              height="36px"
              borderColor="#D0D5DD"
              color="#000"
              iconWidth="22px"
              margin="10px 5px 10px 0px"
              onClick={onClose}
            />

            {genericAccountInfo?.note ? (
              <a
                rel="noreferrer"
                target="_blank"
                href="https://wa.me/+2348029093009"
              >
                <CustomButton
                  variant="contained"
                  customColor="#159AA8"
                  children="Okay"
                  width="270px"
                  height="36px"
                  padding="10px"
                  // onClick={() =>
                  //   (window.location = "https://wa.me/+2348029093009")
                  // }
                />
              </a>
            ) : isUSDModal && false ? (
              <CustomButton
                variant="contained"
                customColor="#159AA8"
                children="See USD Opportunities"
                width="270px"
                height="36px"
                padding="10px"
                onClick={() => {
                  navigate("/opportunities");
                }}
              />
            ) : (
              <CustomButton
                variant="contained"
                customColor="#159AA8"
                children={wallet?.accountNumber ? "I Have Paid" : "Ok"}
                width={wallet?.accountNumber ? "270px" : "130px"}
                height="36px"
                padding="10px"
                onClick={() => {
                  onClose();
                }}
              />
            )}
          </div>
        </div>
        <AmountModal
          open={AmountIsOpen}
          onClose={() => setAmountIsOpen(false)}
        />
      </Box>
    </Modal>
  );
};

export default PaymentModal;
