import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import CustomButton from "../buttons/Button";

const FundModal = ({ open, subTitle, list, type, onClose }) => {
  const [paymentOption, setPaymentOption] = useState("naira");

  const handleChange = (event) => {
    setPaymentOption(event.target.value);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            width: 500,
            maxWidth: "90%",

            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 3,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              top: 15,
              right: 10,
            }}
            onClick={onClose}
          >
            <ClearIcon />
          </IconButton>
          <div className="mb-5">
            {list ? (
              <Typography
                variant="h6"
                component="h2"
                sx={{ marginBottom: "5px" }}
              >
                Where are you sending the money from
              </Typography>
            ) : (
              <>
                {" "}
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{ marginBottom: "5px" }}
                >
                  Select {type === "withdraw" ? "an Account" : "Currency"}
                </Typography>
                <Typography>
                  {subTitle ||
                    `Select the ${
                      type === "withdraw" ? "account" : "desired currency"
                    } you'd like to ${
                      type === "withdraw" ? "withdraw from" : "fund"
                    }.`}
                </Typography>
              </>
            )}
          </div>
          <hr
            style={{
              backgroundColor: "#EAECF0",
            }}
          />
          <RadioGroup
            aria-label="currency"
            name="currency"
            sx={{ marginBottom: 2 }}
            value={paymentOption}
            onChange={handleChange}
          >
            {list ? (
              list.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item}
                  control={<Radio />}
                  label={
                    <div
                      style={{
                        display: "flex",
                        fontSize: "14px",
                        alignItems: "center",
                      }}
                    >
                      {item}
                    </div>
                  }
                />
              ))
            ) : (
              <>
                <FormControlLabel
                  value="naira"
                  control={<Radio />}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src="/Assets/NG.png"
                        alt=""
                        className="w-4 h-4 mr-2"
                      />
                      {type === "withdraw"
                        ? "Naira Account (₦)"
                        : "Nigerian Naira (₦)"}
                    </div>
                  }
                />
                <FormControlLabel
                  value="usd"
                  control={<Radio />}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src="/Assets/US.png"
                        alt=""
                        className="w-4 h-4 mr-2"
                      />
                      {type === "withdraw"
                        ? "Dollar Account ($)"
                        : " US Dollar ($)"}
                    </div>
                  }
                />
              </>
            )}
          </RadioGroup>

          <hr />
          {type === "withdraw" ? (
            <Typography
              variant="body1"
              fontSize={14}
              width={"98%"}
              // height={100}
              style={{
                marginTop: "8px",

                fontWeight: "500",
                fontFamily: "Inter",
                fontSize: "13px",
                color: "#159AA8",
              }}
            >
              {
                "Withdrawal processing typically takes 24-48 hours. Thank you for your patience."
              }
            </Typography>
          ) : (
            <></>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <CustomButton
              variant="outlined"
              customColor="#fff"
              children="Cancel"
              width="130px"
              height="32px"
              borderColor="#D0D5DD"
              color="#000"
              iconWidth="22px"
              onClick={onClose}
            />

            <CustomButton
              variant="contained"
              customColor="#159AA8"
              children="Continue"
              width="230px"
              height="32px"
              margin="10px"
              padding="10px"
              onClick={() => onClose(paymentOption, type)}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FundModal;
